// Anything exported from this file is importable by other in-browser modules.
import React from "react";
import AppButton from "./components/AppButton";
import ApiLoader from "./components/ApiLoader";
import Loading from "./components/Loading";
import SamllLoading from "./components/SmallLoading";
import AppHeader from "./components/AppHeader";
import SimplifiedHeader from "./components/SimplifiedHeader";
import DateHandler from "./components/DateHandler";
import TimeoutLoader from "./components/TimeoutLoader";
import DataTable from "./components/DataTable";
import BasicDialog from "./components/BasicDialog";
import SnackNotification from "./components/SnackNotification";
import VersionPusher from "./components/VersionPusher";
import CookieRequest from "./components/CookieRequest";
import StorageManager from "./components/StorageManager";
import {ActivityTypes} from "./constants/activityTypes";

const AppHeaderComponent = (props) => {
  return <AppHeader {...props} />;
};

const SHeaderComponent = (props) => {
  return <SimplifiedHeader {... props} />;
};

const AppButtonComponent = (props) => {
  return <AppButton {...props} />;
};

const ApiLoaderComponent = (props) => {
  return <ApiLoader {...props} />;
};

const LoadingComponent = (props) => {
  return <Loading {...props} />;
};

const SmallLoadingComponent = (props) => {
  return <SamllLoading {...props} />;
};

const TimeoutLoaderComponent = (props) => {
  return <TimeoutLoader {...props} />;
};

const DataTableComponent = (props) => {
  return <DataTable {...props} />;
};

const BasicDialogComponent = (props) => {
  return <BasicDialog {...props} />;
}

const SnackNotificationComponent = (props) => {
  return <SnackNotification {...props} />;
}

const VersionPusherComponent = (props) => {
  return <VersionPusher {...props} />;
}

const CookieRequestComponent = (props) => {
  return <CookieRequest {...props} />;
}

export * as ClientsApi from "./api/clients";
export * as PersonnelApi from "./api/personnel";
export * as CostingsApi from "./api/costings";
export * as MailerApi from "./api/mailer";
export * as PermissionsApi from "./api/permissions";
export * as ThemeApi from "./api/theme";
export * as TicketApi from "./api/ticket";
export * as TicketActivitiesApi from "./api/ticketActivities";
export * as KnowledgeBaseApi from "./api/knowledge";
export * as CalendarApi from "./api/calendar";
export * as LeadsApi from "./api/leads";
export * as CodesApi from "./api/codes";
export * as CallApi from "./api/calls";
export * as AttendanceApi from "./api/attendance";
export * as MarketingApi from "./api/marketing";
export * as SuppliersApi from "./api/suppliers";
export * as SalesOpApi from "./api/salesOp";
export * as ContractsApi from "./api/contracts";
export * as FormsApi from "./api/forms";
export * as ActivityApi from "./api/activities";
export * as PurchasingApi from "./api/purchasing";
export * as ProductsApi from "./api/products";
export * as EmailApi from "./api/email";
export * as DynamicListApi from "./api/dynamicList";
export * as DocumentVaultAPI from "./api/documentVault";
export * as CampaignApi from "./api/campaign";
export * as ImporterApi from "./api/importer";
export * as TargetsApi from "./api/targets";
export * as SocialMediaApi from "./api/socialMedia";
export * as DocCreatorApi from "./api/docCreator";
export * as NotificationsApi from "./api/notifications";
export * as MaterialsApi from "./api/materials";
export * as FinanceApi from "./api/finance";
export * as FulfilmentApi from "./api/fulfilment";
export * as BusinessApi from "./api/business";
export * as DealBuilderApi from "./api/dealBuilder";
export * as FusionAuthApi from "./api/fusionAuth";
export * as PortalApi from "./api/portal";

export {
  AppHeaderComponent,
  SHeaderComponent,
  AppButtonComponent,
  ApiLoaderComponent,
  LoadingComponent,
  SmallLoadingComponent,
  DateHandler,
  TimeoutLoaderComponent,
  DataTableComponent,
  BasicDialogComponent,
  SnackNotificationComponent,
  VersionPusherComponent,
  CookieRequestComponent,
  StorageManager,

  ActivityTypes
};
